import { LitElement, html, css, svg, type TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import type { Coach } from "@types";


@customElement("nav-bar")
export class NavBar extends LitElement {
  static styles = css`
    :root {
      --radius: .3125rem;
      font-family: "RiseInside", serif;

      @property --rotate {
        syntax: "<angle>";
        initial-value: 0deg;
        inherits: false;
      }
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    nav {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 5rem;
    }

    nav.nav-bar {
      justify-content: space-evenly;
    }

    menu.nav-menu {
      flex-direction: row;
      align-items: center;
      text-context: center;
      list-style-type: none;
      padding: 0;
      margin: 0;
      justify-content: space-evenly;
    }

    a {
      text-decoration: none;
    }

    button {
      cursor: pointer;
    }

    nav menu li {
      position: relative;
      margin: .25rem;
    }

    .button, .link {
      font-family: "RiseInside", serif;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: var(--radius);
      color: var(--color-text-dark);
      cursor: pointer;
      font-size: 1rem;
      text-decoration: none;
      display: inline-block;
      transition: background-color 0.3s ease, transform 0.3s ease;
      background: none;
    }

    nav menu li button div::after, a div::after {
      content: "";
      position: absolute;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: calc(var(--radius) - 0.125rem);
    }


    nav menu li button:hover, a:hover, button:focus, a:focus {
      color: var(--color-white);
      font-size: 1.1rem;
    }

    nav .logo-span:hover,
    nav .logo-span:focus-within {
      outline: -webkit-focus-ring-color auto .0625rem;
    }

    nav .logo-span:hover .logo,
    nav .logo-span:focus-within .logo {
      background: var(--logo-gradient);
    }

    .logo {
      position: relative;
      inset: 0;
      min-width: 8rem;
      min-height: 8rem;
      background-image: var(--logo-gradient);
      -webkit-mask-image: url('/images/valkyrie-fitness-logo.svg');
      mask-image: url('/images/valkyrie-fitness-logo.svg');
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-position: center;
      mask-position: center;
      display: inline-block;
      transition: background-image 0.5s ease, transform 0.5s ease;
    }

    .insta-link, .fb-link {
      min-width: 2rem;
      min-height: 2rem;
      padding: .25rem;
      display: inline-block;
      transition: background-image 0.5s ease, transform 0.5s ease;
    }

    @supports not (mask-image: url('/images/valkyrie-fitness-logo.svg')) {
      .logo {
        background-image: url('/images/valkyrie-fitness-logo.svg'), var(--logo-gradient);
        background-blend-mode: overlay;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      nav .nav-menu .logo-span:hover,
      nav .nav-menu .logo-span:focus-within {
        outline: -webkit-focus-ring-color auto 0.125rem;
      }
    }

    .logo:hover:before, .logo:focus:before {
      content: "";
      background: var(--logo-gradient);
      position: absolute;
      inset: 0 0 0 -9.5%;
      animation: rotate 3s linear infinite;
      height: 9.5rem;
      width: 9.5rem;
    }

    .nav-menu {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      font-size: 120%;
      color: var(--text-primary);
    }

    .visuallyhidden {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    #coach-menu::before {
      content: "";
      inset: 0;
      filter: blur(.25rem);
    }

    #coach-menu {
      display: none;
      margin: 0;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      position: absolute;
      background-color: var(--color-primary);
      border: 0.0625rem solid var(--accent-2-light);
      border-radius: 0.5rem;
      z-index: 1000;
      min-width: 9.375rem;
      white-space: nowrap;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: opacity 0.3s, transform 0.3s;
    }

    #coach-menu li a {
      text-align: left;
      color: var(--color-text-dark)
    }

    #coach-menu:popover-open,
    #coach-menu.fallback-open {
      display: flex;
      flex-direction: column;
      opacity: 1;
      transform: scaleY(1);
    }

    #coach-menu::backdrop {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s;
    }

    #coach-menu:popover-open::backdrop {
      opacity: .8;
    }

    #coach-menu a {
      color: var(--color-white);
      transition: color 0.3s ease, transform 0.3s ease;
      padding: 0.5rem;
      display: block;
    }

    #coach-menu a:hover, #coach-menu a:focus {
      color: var(--accent-3-light);
      transform: translateX(--radius);
    }

    .list-elem-text::before {
      content: "";
      inset: .125rem;
      background: var(--color-primary);
      position: absolute;
      border-radius: calc(var(--radius) - .125rem);
      z-index: -1;
    }

    @layer list {
      .list-elem:hover:before, .list-elem:focus:before, .list-elem[aria-current=page]:before {
        content:"";
        display: block;
        position: absolute;
        z-index: -1;
        inset: -.0625rem;
        filter: blur(.25rem);
        background: var(--logo-gradient);
        border-radius: var(--radius);

        @supports (background: linear-gradient(in oklab, white, black)) {
          --space: in oklab;
        }
      }

      .list-elem:hover:after, .list-elem:focus:after {
        content: "";
        background: conic-gradient(
          from var(--rotate),
          transparent 0%,
          white 5%,
          transparent 10%
        );
        width: 100%;
        height: 300%;
        inset: -100% 0 0 0;
        z-index: -1;
        position: absolute;
        animation: rotate 3s linear infinite;
      }
    }

      @media (max-width: 48rem) {
        nav {
          padding: 0.5rem;
          gap: 1rem;
          justify-content: center
        }

        .nav-bar {
          gap: .5rem;
        }

        .logo-span {
          text-align: center;
        }

        .nav-menu {
          flex-wrap: wrap;
          justify-content: center;
          column-gap: 2rem;
          row-gap: 0;
        }

        nav menu li {
          text-align: center;
        }

        .logo-span {
          flex-basis: 100%;
          order: -1;
          margin-bottom: 1rem;
        }
      }

      /* Desktop styles */
      @media (min-width: 48.0625rem) {
        menu.nav-menu {
          gap: 3rem;
        }

        nav menu li {
          flex-basis: auto;
        }
      }
  `;

  @query('#coach-menu')
  private coachMenu!: HTMLElement;

  @query('#coach-button')
  private coachButton!: HTMLButtonElement;

  @state()
  private supportsPopover = HTMLElement.prototype.hasOwnProperty('popover');

  @property()
  pathname: string = window.location.pathname;

  @property()
  coaches: Coach[] = [];

  firstUpdated() {
    if (this.supportsPopover) {
      // Set the <div> element to be an auto popover
      (this.coachMenu as any).popover = "auto";
      // Set the button popover target to be the popover
      (this.coachButton as any).popoverTargetElement = this.coachMenu;
      // Set that the button toggles popover visibility
      (this.coachButton as any).popoverTargetAction = "toggle";
    } else {
      this.coachMenu.textContent = "Popover API not supported.";
      this.coachButton.hidden = true;
    }
  }

  private handlePopoverChange() {
    const isOpen = this.coachMenu.matches(':popover-open');
    this.coachButton.setAttribute("aria-expanded", isOpen.toString());
    if (isOpen) {
      this.positionMenu();
      // Focus the first link in the menu
      const firstLink = this.coachMenu.querySelector('a');
      if (firstLink) {
        (firstLink as HTMLElement).focus();
      }
    } else {
      this.coachButton.focus();
    }
  }

  private positionMenu() {
    const buttonRect = this.coachButton.getBoundingClientRect();
    this.coachMenu.style.top = `${buttonRect.bottom}px`;
    this.coachMenu.style.left = `${buttonRect.left}px`;
  }

  render() {
    return html`
    <header>
      <nav class="nav-bar">
        <span class="logo-span">
          <a class="logo" aria-label="Valkyrie Fitness Home Page" href="/" aria-current=${this.pathname === "/" ? "page" : false}>
          </a>
        </span>
        <menu class="nav-menu">
          <li>
            <button id="coach-button"
              class="list-elem button"
              aria-haspopup="true"
              aria-expanded="false"
              popoverTarget="coach-menu"
              @click=${() => this.handlePopoverChange()}
            >
              <span class="list-elem-text"></span>
              <span>Coaches</span>
            </button>
            <menu popover id="coach-menu" @toggle=${() => this.handlePopoverChange()}>
              ${this.coaches.map(coach => {
                // const coachName = coach.first_name + " " + coach.last_name;
                const coachUrl = coach.name.replace(/\s/g, "-").toLowerCase();
                return html`
                <li class="link">
                  <a href="/coaches/${coachUrl}" aria-current=${this.pathname === `/coaches/${coachUrl}` ? "page" : false}>${coach.name}</a>
                </li>`
              })}
            </menu>
          </li>
          <li>
            <a class="list-elem link" href="/gym" aria-current=${this.pathname === "/gym" ? "page" : false}>
              <span class="list-elem-text"></span>
              <span>The Gym</span>
            </a>
          </li>
          <li>
            <a class="list-elem link" href="/classes" aria-current=${this.pathname === "/classes" ? "page" : false}>
              <span class="list-elem-text"></span>
              <span>Classes</span>
            </a>
          </li>
          <li>
            <a class="list-elem link fb-link" aria-label="Valkyrie Fitness Facebook" href="https://www.facebook.com/profile.php?id=61564647127106">
              <img src="/images/Facebook_Logo_Primary.png" alt="" height="35" width="35" />
            </a>
          </li>
          <li>
            <a class="list-elem link insta-link" aria-label="Valkyrie Fitness Instagram" href="https://www.instagram.com/valkyrie_fitness_nv">
              <img src="/images/Instagram_Glyph_Gradient.png" alt="" height="35" width="35" />
            </a>
          </li>
        </menu>
        </nav>
      </header>
    `;
  }
}